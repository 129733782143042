import { BvTableCtxObject } from "bootstrap-vue"
import { Column } from "@/models/table"

export const sortElements = (ctx: BvTableCtxObject, columns: Column[]) => {
  const sortKey = ctx.sortBy?.replace('attributes.', '')
  const sortColumn = sortKey ? columns.find(col => col.key === `attributes.${sortKey}`): undefined
  const sortFunction = sortColumn?.options?.sortFunction
  return { sortKey, sortColumn, sortFunction }
}

export const sortData = (ctx: BvTableCtxObject, data: any[], sortFunction: Function) => {
  if (!ctx.sortBy) return
  if (ctx.sortDesc) {
    const paths = ctx.sortBy.split('.') 
    data.sort((a, b) => {
      const firstEl = paths.reduce((object, path) => (object || {})[path], a) 
      const secondEl = paths.reduce((object, path) => (object || {})[path], b) 
      return sortFunction(firstEl, secondEl)
    })
  } else {
    const paths = ctx.sortBy.split('.') 
    data.sort((a, b) => {
      const firstEl = paths.reduce((object, path) => (object || {})[path], b) 
      const secondEl = paths.reduce((object, path) => (object || {})[path], a) 
      return sortFunction(firstEl, secondEl)
    })
  }
}
